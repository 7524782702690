//imports
import { Scene, WebGLRenderer, PCFSoftShadowMap, SRGBColorSpace, NeutralToneMapping, LinearToneMapping, CineonToneMapping, ACESFilmicToneMapping, ReinhardToneMapping, PerspectiveCamera, Cache, EquirectangularReflectionMapping, VSMShadowMap } from 'three';
import * as lights from './lights';
import { OrbitControls } from '../OrbitControls';
import { RGBELoader } from 'three/addons/loaders/RGBELoader.js';

// Three cache
Cache.enabled = true;

// scene
let scene = new Scene();

// renderer
let renderer = new WebGLRenderer({ alpha: false, antialias: true, preserveDrawingBuffer: true, powerPreference: 'low-power' });
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.setPixelRatio(1)
renderer.capabilities.getMaxAnisotropy()
renderer.capabilities.getMaxPrecision()
renderer.setClearColor(0xF7F7FA, 1);
renderer.gammaOutput = true;
renderer.shadowMap.enabled = false
// renderer.shadowMap.autoUpdate = true;
// renderer.shadowMap.needsUpdate = true;
//renderer.shadowMap.type = PCFSoftShadowMap; // PCFSoftShadowMap
renderer.outputColorSpace = SRGBColorSpace;
renderer.toneMapping = NeutralToneMapping;
renderer.toneMappingExposure = 4;
//renderer.localClippingEnabled = true;
document.body.prepend(renderer.domElement);

// cameras
const camera = new PerspectiveCamera(35, window.innerWidth / window.innerHeight, 0.1, 1000);
camera.position.x = 0;
camera.position.z = 4;
//camera.position.y = -1.5;

// lights
//scene.add(lights.ambiLight);
// scene.add(lights.hemiLight);
// scene.add(lights.directLight);
// scene.add(lights.backLight);

// environment HDR map
let standaloneHDR
new RGBELoader().setPath('images/').load('hdr7.hdr', function (hdrmap) {
    hdrmap.mapping = EquirectangularReflectionMapping;
    scene.environment = hdrmap
    standaloneHDR = hdrmap
})

// orbit controls
const oControls = new OrbitControls(camera, renderer.domElement);
oControls.enablePan = false;
oControls.rotateSpeed = 0.025; // 0.025
oControls.enableZoom = false;
oControls.zoomToCursor = false;
oControls.maxDistance = 4;
oControls.minDistance = 1;
oControls.enableDamping = true;
oControls.dampingFactor = .05;
oControls.maxPolarAngle = Math.PI / 2;
oControls.minPolarAngle = Math.PI / 2;
oControls.minAzimuthAngle = -(Math.PI / 5) // 20
oControls.maxAzimuthAngle = (Math.PI / 5)  // 20
oControls.enableRotate = false;

// if (mobile) {
//     oControls.screenSpacePanning = true;
//     oControls.touches.ONE = THREE.TOUCH.PAN;
//     oControls.touches.TWO = THREE.TOUCH.DOLLY_ROTATE;
//     oControls.minAzimuthAngle = 0
//     oControls.maxAzimuthAngle = 0
// }

export {
    scene,
    renderer,
    camera,
    oControls,
    standaloneHDR
}